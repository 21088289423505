var render = function (_h,_vm) {var _c=_vm._c;return _c('div',[(_vm.props.tooltip.data.flow)?_c('div',{staticClass:"text-h6 text-truncate"},[_vm._v(" "+_vm._s(_vm.props.tooltip.data.flow.name)+" ")]):(_vm.props.loading)?_c('div',[_vm._v(" ... ")]):_vm._e(),_c('div',{staticClass:"text-truncate",class:_vm.props.tooltip.data.flow || _vm.props.loading ? 'text-caption' : 'text-h6'},[_vm._v(" "+_vm._s(_vm.props.tooltip.data.name)+" ")]),_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{style:(_vm.props.tooltip.status_style)}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.props.tooltip.data.state))])]),_c('div',{staticClass:"divider"}),(
      _vm.props.showProjectName &&
        !_vm.props.loading &&
        _vm.props.tooltip.data.flow.project.name
    )?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Project: "),_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.props.tooltip.data.flow.project.name)+" ")])]):_vm._e(),(!_vm.props.tooltip.limited_view)?_c('div',[(_vm.props.tooltip.data.state == 'Scheduled')?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Scheduled for: "),_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.props.tooltip.data.display_scheduled_start_time)+" ")])]):_vm._e(),(_vm.props.tooltip.data.start_time)?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Started: "),_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.props.tooltip.data.display_start_time)+" ")])]):_vm._e(),(_vm.props.tooltip.data.end_time)?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Ended: "),_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.props.tooltip.data.display_end_time)+" ")])]):_vm._e(),(
        _vm.props.tooltip.data.start_time &&
          (_vm.props.tooltip.data.end_time || !_vm.props.tooltip.data.finished)
      )?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Duration: "),_c(_vm.$options.components.DurationSpan,{tag:"component",staticClass:"font-weight-bold",attrs:{"start-time":_vm.props.tooltip.data.start_time,"end-time":_vm.props.tooltip.data.end_time}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }